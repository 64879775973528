import React, { Component } from 'react';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Home from './HomeComponent';
import About from './AboutComponent';
import Products from './ProductsComponent';
import Contact from './ContactComponent';
import Register from './RegisterComponent';
import Settings from './SettingsComponent';
import Auth from './AuthComponent';
import ProductDetail from './ProductDetail';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchProducts, fetchCarousel, fetchLeaders } from '../redux/ActionCreators';

const mapStateToProps = (state) => {
    return {
        user: state.userReducer,
        products: state.productsReducer,
        leaders: state.leadersReducer,
        carousel: state.carouselReducer
    }
}

const mapDispatchToProps = dispatch => ({
    fetchProducts: () => dispatch(fetchProducts()),
    fetchCarousel: () => dispatch(fetchCarousel()),
    fetchLeaders: () => dispatch(fetchLeaders())
});

class Main extends Component {
    componentDidMount() {
        this.props.fetchProducts();
        this.props.fetchCarousel();
        this.props.fetchLeaders();
    }

    render() {

        const ProductWithId = ({ match }) => {
            return (
                <ProductDetail product={this.props.products.products.filter((product) => product.id === parseInt(match.params.productId, 10))[0]}
                    isLoading={this.props.products.isLoading}
                    productId={match.params.productId}
                />
            );
        }

        const PrivateRoute = ({ render: Render, ...rest }) => (
            <Route {...rest} render={(props) => (
                this.props.user.signedIn
                    ? <Render {...props} />
                    : <Redirect to={{
                        pathname: '/home',
                        state: { from: props.location }
                    }} />
            )} />
        );

        const UnAuthRoute = ({ render: Render, ...rest }) => (
            <Route {...rest} render={(props) => (
                this.props.user.signedIn
                    ?
                    <Redirect to={{
                        pathname: '/home',
                        state: { from: props.location }
                    }} />
                    : <Render {...props} />
            )} />
        );

        return (
            <div>
                <Header />
                <Switch>
                    <Route path='/home' render={() => <Home />} />
                    <Route exact path='/aboutus' render={() => <About carousel={this.props.carousel} leaders={this.props.leaders} />} />
                    <Route exact path='/products' render={() => <Products products={this.props.products} />} />
                    <Route path='/products/:productId' render={ProductWithId} />
                    <Route exact path='/contactus' render={() => <Contact resetFeedbackForm={this.props.resetFeedbackForm} />} />
                    <UnAuthRoute exact path='/__auth/action' render={({ location }) => <Auth location={location} />} />
                    <UnAuthRoute exact path='/register' render={() => <Register />} />
                    <PrivateRoute exact path='/settings' render={() => <Settings />} />
                    <Redirect to="/home" />
                </Switch>
                <Footer />
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));