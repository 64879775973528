import * as ActionTypes from './ActionTypes';

export const commentsReducer = (state = [], action) => {
    switch (action.type) {
        case ActionTypes.ADD_COMMENTS:
            return action.payload;

        default:
            return state;
    }
}