import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const fbase = firebase.initializeApp({
    apiKey: "AIzaSyBrte57KDAhpXkfGBV5wBdhbBC1h_zrG2c",
    authDomain: "loopus-weartech.firebaseapp.com",
    databaseURL: "https://loopus-weartech.firebaseio.com",
    projectId: "loopus-weartech",
    storageBucket: "loopus-weartech.appspot.com",
    messagingSenderId: "360868037162",
    appId: "1:360868037162:web:0307216bccaf12eefeb953",
    measurementId: "G-GX87N3P943"
});

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

export const fbBaseUrl = "https://identitytoolkit.googleapis.com/v1/"

export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref;

export const googleAuth = new firebase.auth.GoogleAuthProvider();
export const facebookAuth = new firebase.auth.FacebookAuthProvider();