import React, { useState } from 'react';
import Fade from 'react-reveal/Fade'

const imgPath = {
    vert: "https://firebasestorage.googleapis.com/v0/b/loopus-weartech.appspot.com/o/images%2FFD_V.png?alt=media&token=b897217c-8567-49de-a26d-08cb9ee3f535",
    small: "https://firebasestorage.googleapis.com/v0/b/loopus-weartech.appspot.com/o/images%2FFD_S.png?alt=media&token=44102f13-7b3a-4a21-a763-ad75fad471c8"
}

const text = {
    vert: "(Click the Watch to Fold)",
    small: "(Click the Watch to Unfold)"
}

const styleImgRow = {
    vert: {
        margin: "25% 0 10% 0"
    },
    small: {
        margin: "85% 0 60% 0"
    }
}

function Home() {

    const [vert, setVert] = useState(true)

    const changeImage = () => {
        setVert(!vert)
    }

    const Name = () => vert ? 'vert' : 'small'

    const stylepTag = {
        fontSize: "60%"
    }

    const styleRow = {
        textAlign: "center",
    }

    const StyleImg = {
        height: "100%",
        width: "80%"
    }

    return (
        <div className="container">
            <div className="row" style={styleRow}>
                <div className="col-12 col-md-5 offset-md-1 align-self-center mt-4 mb-4">
                    <Fade bottom>
                        <h2>Wearable for Elderly</h2>
                        <p style={stylepTag}>{text[Name()]}</p>
                    </Fade >
                </div>
                <div className="col-12 col-md-6" style={styleImgRow[Name()]}>
                    <Fade>
                        <img src={imgPath[Name()]} style={StyleImg} alt="Wearable for Elderly" onClick={changeImage} />
                    </Fade>
                </div>
            </div>

        </div >
    );
}

export default Home;