import * as ActionTypes from './ActionTypes';

export const userReducer = (state =
    {
        username: '',
        email: '',
        signedIn: false,
        photoUrl: '',
        emailVerified: false,
        uid: null,
        dob: null,
        remember: false,
        loading: false,
        admin: false,
        err: null,
        info: null,
        customProvider: true
    }, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                username: action.payload.username,
                email: action.payload.email,
                signedIn: true,
                photoUrl: action.payload.photoUrl,
                uid: action.payload.uid,
                dob: action.payload.dob,
                admin: action.payload.admin,
                remember: action.payload.remember,
                customProvider: action.payload.customProvider,
                loading: false,
                err: null
            }

        case ActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                username: '',
                email: '',
                signedIn: false,
                photoUrl: '',
                emailVerified: false,
                uid: null,
                dob: null,
                remember: false,
                loading: false,
                admin: false,
                err: null,
                info: null,
                customProvider: true
            }

        case ActionTypes.LOGIN_FAIL:
            return {
                ...state,
                signedIn: false,
                loading: false,
                err: action.payload,
                info: null
            }

        case ActionTypes.LOGOUT_FAIL:
            return {
                ...state,
                signedIn: true,
                loading: false,
                err: action.payload,
                info: null
            }

        case ActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                signedIn: false,
                loading: true,
                err: null
            }

        case ActionTypes.LOGOUT_REQUEST:
            return {
                ...state,
                signedIn: true,
                loading: true,
                err: null
            }

        case ActionTypes.VERIFY_EMAIL:
            return {
                ...state,
                emailVerified: true,
                err: null
            }

        case ActionTypes.CHANGE_USERNAME:
            return {
                ...state,
                username: action.payload,
                err: null
            }

        case ActionTypes.SET_PHOTOURL:
            return {
                ...state,
                photoUrl: action.payload,
                err: null
            }

        case ActionTypes.SET_INFO:
            return {
                ...state,
                loading: false,
                info: action.payload,
                err: null
            }

        case ActionTypes.RESET_INFO:
            return {
                ...state,
                loading: false,
                info: null
            }

        case ActionTypes.SET_REQUEST:
            return {
                ...state,
                loading: true,
                err: null
            }

        case ActionTypes.END_REQUEST:
            return {
                ...state,
                loading: false,
                err: null
            }

        case ActionTypes.SET_ERROR:
            return {
                ...state,
                loading: false,
                err: action.payload
            }

        case ActionTypes.RESET_ERROR:
            return {
                ...state,
                loading: false,
                err: null
            }

        default:
            return state;
    }
}