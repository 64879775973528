import React, { useEffect, useState } from 'react';
import { baseUrl } from '../shared/baseUrl'

function Loading(props) {

    const [color, setColor] = useState("white");
    const [size, setSize] = useState("sm");
    const [variant, setVariant] = useState("grow");

    useEffect(() => {

        setColor(props.color);
        setSize(props.size);
        setVariant(props.variant);

        // eslint-disable-next-line
    }, [])

    const width = {
        xs: "75",
        sm: "120",
        md: "180"
    }

    const height = {
        xs: "50",
        sm: "80",
        md: "120"
    }

    if (variant === "grow")
        return (
            <img className="grow-load" src={baseUrl + `images/favicon_${color}.png`} width={width[size]} height={height[size]} alt="loading white" />
        );
    else if (variant === "spin")
        return (<div className="spin-load"></div>);
    else
        return (<></>)
}


export default Loading;

/* <Spinner type="grow" color="light" size="md" /> */