import React, { useState } from 'react';
import { Button, Col, Row, CustomInput, FormText, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Loading from './LoadingComponent';
import { logoutUser, resetPassword, deleteAccount, updateName, uploadPhoto } from '../redux/ActionCreators';
import ReactCrop from 'react-image-crop';
import { baseUrl } from '../shared/baseUrl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(logoutUser()),
    resetPassword: (email) => dispatch(resetPassword(email)),
    deleteAccount: () => dispatch(deleteAccount()),
    updateName: (name) => dispatch(updateName(name)),
    uploadPhoto: (image) => dispatch(uploadPhoto(image))
});

function Settings(props) {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [image, setImage] = useState(null);
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({
        unit: 'px',
        aspect: 1 / 1
    });

    const handleChange = (files) => {
        if (files && files[0])
            setSrc(URL.createObjectURL(files[0]));
        else
            setSrc(null);
    }

    document.addEventListener('dragover', (e) => {
        e.preventDefault()
        e.stopPropagation()
    }, false)

    document.addEventListener('drop', (e) => {
        e.preventDefault()
        e.stopPropagation()
        handleChange(e.dataTransfer.files);
    }, false)

    const handleUpload = () => {
        if (image) {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            if (crop.width > 0 || crop.height > 0) {
                canvas.width = crop.width;
                canvas.height = crop.height;
            } else if (image.width === image.height) {
                canvas.width = image.width;
                canvas.height = image.height;
            } else {
                alert("Please crop the Image and try again");
                return;
            }
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height,
            );

            new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    blob.name = props.user.uid;
                    blob.lastModified = new Date().toISOString();
                    resolve(blob);
                }, 'image/jpeg', 1);
            })
                .then((imageBlob) => {
                    if (imageBlob.size > 2 * 1024 * 1024) {
                        alert("Select a lesser size Image")
                        return;
                    }
                    props.uploadPhoto(imageBlob);
                });
        } else {
            alert("Select an Image and then click Upload");
        }
    }

    const handleLogout = () => {
        props.logoutUser();
    }

    const changePassword = () => {
        props.resetPassword(props.user.email);
    }

    const changeDisplayName = () => {
        props.updateName(fname + " " + lname)
    }

    const handleDelete = () => {
        props.deleteAccount();
    }

    const styleRow = {
        margin: "20% 2% 2% 2%",
        padding: "4% 0 20% 0",
        borderBottom: "0.1px white solid"
    }

    return (
        <div className="container" >
            <Breadcrumb>
                <BreadcrumbItem ><Link to='/home'>Home</Link></BreadcrumbItem>
                <BreadcrumbItem active>Settings</BreadcrumbItem>
            </Breadcrumb>
            <div className="row">
                <div className="col-10 offset-xs-2 mt-4">
                    <h3>Settings</h3>
                </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "20%" }} className="row justify-content-center">
                {
                    props.user.photoUrl ?
                        <img src={props.user.photoUrl} style={{ borderRadius: "50%" }} width="20%" height="20%" alt="" /> :
                        <img src={baseUrl + "images/nodp.png"} style={{ borderRadius: "50%" }} width="15%" height="15%" alt="logo white" />
                }
            </div>
            <div style={{ ...styleRow, marginTop: "2%", paddingTop: "2%" }} className="row justify-content-center">
                <h4 >{props.user.username}</h4>
            </div>
            <div style={styleRow}>
                <div style={{ margin: "0 2% 2% 0" }} className="row align-items-center col-md-9">
                    <h5> Change your display name? </h5>
                </div>
                <div style={{ margin: "2%" }} className="row align-items-center justify-content-center">
                    <Input type="text"
                        className="offset-md-1 col-md-3 mt-3"
                        name="newfirstName"
                        id="newfirstName"
                        placeholder="First Name"
                        onBlur={e => setFname(e.target.value)} />
                    <Input type="text"
                        className="offset-md-1 col-md-3 mt-3"
                        name="newlastName"
                        id="newlastName"
                        placeholder="Last Name"
                        onBlur={e => setLname(e.target.value)} />
                    <Button className="offset-md-1 mt-3" color="primary" type="submit" onClick={changeDisplayName}> Change </Button>
                </div>
            </div>
            {
                props.user.customProvider &&
                <>
                    <div style={styleRow} className="row align-items-center justify-content-center">
                        <div className="col-md-9 mb-4">
                            <h5> Reset or Update your Password? </h5>
                        </div>
                        <Button
                            color="primary" className="col-md-3 mb-4"
                            onClick={changePassword} type="button"> Reset Password </Button>
                    </div>
                    <div style={styleRow}>
                        <Row className="form-group justify-content-center align-items-center">
                            <h5 className="col-md-3"> Upload Photo </h5>
                            <Col md={9}>
                                <CustomInput type="file" id="exampleCustomFileBrowser"
                                    name="customFile"
                                    label="Select an Image File"
                                    onChange={e => handleChange(e.target.files)} accept='image/png | image/jpeg | image/jpg'
                                />
                                <div style={{ marginTop: "2%", height: "50%", width: "100%", backgroundColor: "white", borderRadius: "10px" }}>
                                    <h6 style={{ textAlign: "center", padding: "5% 0 5% 0", color: "#121212" }}> Drag and Drop File </h6>
                                </div>
                                <FormText>Upload only PNG, JPG or JPEG Image files</FormText>
                            </Col>
                            {
                                src &&
                                <div style={{ marginTop: "5%", width: "50%", height: "50%" }} >
                                    <ReactCrop src={src} crop={crop} onImageLoaded={img => setImage(img)} onChange={c => setCrop(c)} style={{ margin: "auto" }} />
                                    <FormText>Crop the Image in 1:1 and click Upload</FormText>
                                </div>
                            }
                        </Row>
                        <div style={{ textAlign: "center" }}>
                            {
                                props.user.loading ?
                                    <Loading variant="grow" color="white" size="sm" /> :
                                    <Button onClick={handleUpload} color="primary"> Upload Photo </Button>
                            }
                        </div>
                    </div>
                </>
            }
            <div className="row align-items-center justify-content-center" style={{ margin: "25% 0 10% 0" }}>
                <Button outline onClick={handleLogout}
                    className="col-6" >
                    <span className="fa fa-sign-out fa-lg"> </span> Logout
                </Button>
            </div>
            <div className="row align-items-center justify-content-center" style={{ margin: "10% 0 25% 0" }}>
                <Button style={{ backgroundColor: "#d11a2a", color: "white", borderColor: "#d11a2a" }}
                    onClick={handleDelete}
                    className="col-6"
                >
                    <span className="fa fa-trash fa-lg"> </span> Delete your account
                </Button>
            </div>
        </div>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Settings);