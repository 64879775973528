import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createForms } from 'react-redux-form';
import { productsReducer } from './productsReducer';
import { commentsReducer } from './commentsReducer';
import { leadersReducer } from './leadersReducer';
import { userReducer } from './userReducer';
import { carouselReducer } from './carouselReducer';
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { initialFeedback, initialRegister, initialLogin } from './initialForms';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['userReducer']
}

export const store = createStore(
    persistReducer(
        persistConfig,
        combineReducers({
            productsReducer,
            commentsReducer,
            leadersReducer,
            userReducer,
            carouselReducer,
            ...createForms({
                feedback: initialFeedback,
                register: initialRegister,
                login: initialLogin
            })
        })
    ),
    //applyMiddleware(thunk, logger)
    applyMiddleware(thunk)
);

export const persistor = persistStore(store);