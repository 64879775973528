import aes from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export const initialFeedback = {
    fullname: '',
    telnum: '',
    email: '',
    agree: false,
    contactType: 'Tel.',
    message: ''
};

export const initialRegister = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    verifyPassword: '',
    dob: ''
};

var initialLoginDetails;

if (localStorage.getItem('loginDetails')) {
    let decryptedDetails = JSON.parse(aes.decrypt(localStorage.getItem("loginDetails"), "LoopusWearTech").toString(Utf8))
    initialLoginDetails = {
        email: decryptedDetails.email,
        password: decryptedDetails.password,
        remember: true
    }
} else {
    initialLoginDetails = {
        email: '',
        password: '',
        remember: false
    }
}

export const initialLogin = initialLoginDetails;