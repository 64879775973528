import * as ActionTypes from './ActionTypes';

export const leadersReducer = (state = {
    isLoading: false,
    leaders: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_LEADERS:
            return { ...state, isLoading: false, leaders: action.payload };

        case ActionTypes.LEADERS_LOADING:
            return { ...state, isLoading: true, leaders: [] }

        default:
            return state;
    }
};