import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Col, Label, Row } from 'reactstrap';
import Loading from './LoadingComponent';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { emailVerification, changePassword } from '../redux/ActionCreators';

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => ({
    emailVerification: (apiKey, oobCode) => { dispatch(emailVerification(apiKey, oobCode)) },
    changePassword: (apiKey, oobCode, newPassword) => { dispatch(changePassword(apiKey, oobCode, newPassword)) }
});

var password = "";

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const setPassword = (val) => password = val;
const passwordMatch = (val) => val === password;

function Auth(props) {

    const [mode, setMode] = useState("")
    const [apiKey, setApiKey] = useState("")
    const [oobCode, setOobCode] = useState("")

    useEffect(() => {
        const query = new URLSearchParams(props.location.search)
        setMode(query.get("mode"));
        setApiKey(query.get("apiKey"));
        setOobCode(query.get("oobCode"));

        // eslint-disable-next-line
    }, []);

    const handleVerifyEmail = () => {
        props.emailVerification(apiKey, oobCode);
    }

    const handleNewPassword = (values) => {
        props.changePassword(apiKey, oobCode, values.password);
    }

    if (props.user.loading) {

        return (
            <div className="row justify-content-center">
                <Loading variant="grow" color="white" size="md" />
            </div>
        );

    } else if (mode === "verifyEmail") {
        return (
            <div className="container" >
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Email Verification</BreadcrumbItem>
                </Breadcrumb>

                <div className="row">
                    <div className="col-11 offset-xs-1 mt-4">
                        <h3>Email Verification</h3>
                    </div>
                </div>

                <div className="row justify-content-center" style={{ margin: "30% 0 30% 0" }}>
                    <Button color="primary" onClick={handleVerifyEmail} className="col-6">
                        Verify Email
                    </Button>
                </div>
            </div>
        );
    } else if (mode === "resetPassword") {
        return (
            <div className="container" >
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Reset Password</BreadcrumbItem>
                </Breadcrumb>

                <div className="row">
                    <div className="col-11 offset-xs-1 mt-4">
                        <h3>Reset Password</h3>
                    </div>
                </div>

                <div style={{ margin: "30% 10% 30% 10%" }}>
                    <LocalForm onSubmit={(values) => handleNewPassword(values)}>
                        <Row className="form-group">
                            <Label htmlFor="password" md={3}>Password</Label>
                            <Col md={9}>
                                <Control.text model=".password" id="password" name="password" type="password"
                                    placeholder="Enter your new Password"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(7), maxLength: maxLength(15), setPassword
                                    }}
                                />
                                <Errors
                                    className="text-danger"
                                    model=".password"
                                    show="touched"
                                    messages={{
                                        minLength: 'Must be greater than 6 characters.',
                                        maxLength: 'Cannot exceed 15 characters.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="verifyPassword" md={3}>Verify Password</Label>
                            <Col md={9}>
                                <Control.text model=".veriyPassword" id="verifyPassword" name="verifyPassword" type="password"
                                    placeholder="Verify your new Password"
                                    className="form-control"
                                    validators={{
                                        required, passwordMatch
                                    }}
                                />
                                <Errors
                                    className="text-danger"
                                    model=".verifyPassword"
                                    show="touched"
                                    messages={{
                                        passwordMatch: 'Passwords do not match each other.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group" style={{ marginTop: "6%" }}>
                            <Col md={{ size: 10, offset: 3 }}>
                                <Button type="submit" color="primary">
                                    Reset Password
                                </Button>
                            </Col>
                        </Row>
                    </LocalForm>
                </div>
            </div>
        );
    }
    else {
        return (<></>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);