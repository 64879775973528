import * as ActionTypes from './ActionTypes';
import { fbase, googleAuth, facebookAuth, db, storage, fbBaseUrl } from '../shared/fireBaseConfig';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import aes from 'crypto-js/aes';

export const setInfo = (info) => {
    return {
        type: ActionTypes.SET_INFO,
        payload: info
    }
}

export const resetInfo = () => {
    return {
        type: ActionTypes.RESET_INFO
    }
}

export const setRequest = () => {
    return {
        type: ActionTypes.SET_REQUEST
    }
}

export const endRequest = () => {
    return {
        type: ActionTypes.END_REQUEST
    }
}

export const setError = (err) => {
    return {
        type: ActionTypes.SET_ERROR,
        payload: err
    }
}

export const resetError = () => {
    return {
        type: ActionTypes.RESET_ERROR
    }
}

export const leadersLoading = () => {
    return {
        type: ActionTypes.LEADERS_LOADING
    };
}

export const addLeaders = (leaders) => ({
    type: ActionTypes.ADD_LEADERS,
    payload: leaders
});

export const fetchLeaders = () => (dispatch) => {
    dispatch(leadersLoading());

    db.collection("leaders").get().then((querySnapshot) => {
        let leaders = [];
        querySnapshot.forEach((doc) => {
            leaders.push(doc.data());
        });
        dispatch(addLeaders(leaders));
    })
        .catch((error) => {
            dispatch(setError(error));
            window.location.reload();
        });

}

export const productsLoading = () => {
    return {
        type: ActionTypes.PRODUCTS_LOADING
    };
}

export const addProducts = (products) => ({
    type: ActionTypes.ADD_PRODUCTS,
    payload: products
});

export const fetchProducts = () => (dispatch) => {
    dispatch(productsLoading());

    db.collection("products").get().then((querySnapshot) => {
        let products = [];
        querySnapshot.forEach((doc) => {
            products.push(doc.data());
        });
        dispatch(addProducts(products));
    })
        .catch((error) => {
            dispatch(setError(error));
            window.location.reload();
        });
}

export const carouselLoading = () => {
    return {
        type: ActionTypes.CAROUSEL_LOADING
    };
}

export const addCarousel = (carousel) => ({
    type: ActionTypes.ADD_CAROUSEL,
    payload: carousel
});

export const fetchCarousel = () => (dispatch) => {
    dispatch(carouselLoading());

    db.collection("carousel").get().then((querySnapshot) => {
        let carousel = [];
        querySnapshot.forEach((doc) => {
            carousel.push(doc.data());
        });
        dispatch(addCarousel(carousel));
    })
        .catch((error) => {
            dispatch(setError(error));
            window.location.reload();
        });
}

export const postComment = (productId, rating, comment) => (dispatch) => {
    dispatch(setRequest());

    if (navigator.onLine) {
        var user = fbase.auth().currentUser;
        var presentTime = new Date().toDateString();

        db.collection("comments").get()
            .then(snapshot => {
                if (!snapshot.data) {
                    db.collection("comments").doc(productId).collection("users")
                        .doc(user.uid).set({
                            username: user.displayName,
                            rating: rating,
                            uid: user.uid,
                            comment: comment,
                            createdAt: presentTime,
                            updatedAt: presentTime
                        });
                    dispatch(setInfo("Your comment has been noted. If it doesn't show on the website, please wait for sometime & reload the page."));
                } else {
                    db.collection("comments").doc(productId).get()
                        .then(snapshot => {
                            if (!snapshot.data) {
                                db.collection("comments").doc(productId).collection("users")
                                    .doc(user.uid).set({
                                        username: user.displayName,
                                        rating: rating,
                                        uid: user.uid,
                                        comment: comment,
                                        createdAt: presentTime,
                                        updatedAt: presentTime
                                    });
                                dispatch(setInfo("Your comment has been noted. If it doesn't show on the website, please wait for sometime & reload the page."));
                            } else {
                                db.collection("comments").doc(productId).collection("users")
                                    .doc(user.uid).get()
                                    .then(snapshot => {
                                        if (!snapshot.data) {
                                            db.collection("comments").doc(productId).collection("users")
                                                .doc(user.uid).set({
                                                    username: user.displayName,
                                                    rating: rating,
                                                    uid: user.uid,
                                                    comment: comment,
                                                    createdAt: presentTime,
                                                    updatedAt: presentTime
                                                });
                                            dispatch(setInfo("Your comment has been noted. If it doesn't show on the website, please wait for sometime & reload the page."));
                                        } else {
                                            db.collection("comments").doc(productId).collection("users")
                                                .doc(user.uid).update({
                                                    rating: rating,
                                                    comment: comment,
                                                    updatedAt: presentTime
                                                });
                                            dispatch(setInfo("Your comment has been updated. If it doesn't show on the website, please wait for sometime & reload the page."));
                                        }
                                    })
                            }
                        })
                }
            })
            .catch(error => {
                dispatch(setError(error));
                window.location.reload();
            })
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }

    dispatch(endRequest());
    window.location.reload();
}

export const deleteComment = (productId) => (dispatch) => {
    dispatch(setRequest());

    var user = fbase.auth().currentUser;

    if (navigator.onLine) {
        db.collection("comments").doc(productId)
            .collection("users").doc(user.uid).delete()
            .then(() => {
                dispatch(setInfo("Comment has been successfully deleted"));
                window.location.reload();
            })
            .catch(error => {
                dispatch(setError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }

    dispatch(endRequest());
}

export const addComments = (comments) => {
    return {
        type: ActionTypes.ADD_COMMENTS,
        payload: comments
    }
}

export const fetchComments = (productId) => (dispatch) => {
    dispatch(setRequest());

    if (navigator.onLine) {
        db.collection("comments").doc(productId).collection("users").get()
            .then((querySnapshot) => {
                let userComments = [];
                querySnapshot.forEach((doc) => {
                    userComments.push(doc.data());
                });
                dispatch(addComments(userComments));
            }).catch(error => {
                dispatch(setError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }

    dispatch(endRequest());
}

export const setPhotoUrl = (photoUrl) => {
    return {
        type: ActionTypes.SET_PHOTOURL,
        payload: photoUrl
    }
}

export const uploadPhoto = (imageFile) => async (dispatch) => {

    var user = fbase.auth().currentUser;
    const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    try {
        const compressedImage = await imageCompression(imageFile, options);
        console.log(`compressedFile size ${compressedImage.size / 1024 / 1024} MB`);

        var uploadTask = storage.ref(`userPhotos/${user.uid}`).put(compressedImage);
        uploadTask.on('state_changed',
            (snapshot) => {
                dispatch(setRequest());
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            error => {
                dispatch(setError(error));
                window.location.reload();
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at ', downloadURL);
                    db.collection("userProfile").doc(user.uid).update({ photoUrl: downloadURL })
                        .then(() => {
                            dispatch(setPhotoUrl(downloadURL));
                            dispatch(setInfo('Your photo is succesfully updated'));
                            dispatch(endRequest());
                            window.location.reload();
                        })
                })
                    .catch(error => {
                        db.collection("userProfile").doc(user.uid).update({ photoUrl: "" });
                        dispatch(setError(error));
                        window.location.reload();
                    });
            }
        );
    } catch (error) {
        dispatch(setError(error));
        window.location.reload();
    }
}

export const registerUser = (displayName, email, password, dob) => (dispatch) => {
    dispatch(setRequest());

    if (navigator.onLine) {
        fbase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(() => {
                fbase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .catch(error => {
                        dispatch(setError(error));
                        window.location.reload();
                    });

                fbase
                    .auth()
                    .onAuthStateChanged((user) => {
                        if (user) {
                            db.collection("userProfile").doc(user.uid).set({
                                username: displayName,
                                email: email,
                                dob: dob,
                                admin: false,
                                uid: user.uid
                            })
                                .catch(error => {
                                    user.delete();
                                    dispatch(setError(error));
                                    window.location.reload();
                                });
                            user.sendEmailVerification().then(() => {
                                fbase.auth().signOut();
                                dispatch(setInfo('An email has been sent to your account for verification, please verify it for logging in'));
                                window.location.reload();
                            })
                            user.updateProfile({ displayName: displayName })
                        }
                    })
                dispatch(endRequest());
            })
            .catch(error => {
                dispatch(setError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }
};

export const verifyEmail = () => {
    return {
        type: ActionTypes.VERIFY_EMAIL
    }
}

export const requestLogin = () => {
    return {
        type: ActionTypes.LOGIN_REQUEST
    }
}

export const loginSuccess = (user) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        payload: user
    }
}

export const loginError = (err) => {
    return {
        type: ActionTypes.LOGIN_FAIL,
        payload: err
    }
}

export const loginUser = (email, password, remember) => (dispatch) => {
    dispatch(requestLogin());

    if (navigator.onLine) {
        fbase.auth().signInWithEmailAndPassword(email, password)
            .catch(error => {
                dispatch(loginError(error));
                window.location.reload();
            });

        fbase
            .auth()
            .onAuthStateChanged((user) => {
                if (user) {
                    if (user.emailVerified) {
                        dispatch(verifyEmail());
                        let userProfile = {
                            email: user.email,
                            uid: user.uid,
                            remember: remember
                        }
                        db.collection('userProfile').doc(user.uid).get()
                            .then((snapshot) => {
                                if (snapshot.data()) {
                                    userProfile.username = snapshot.data().username;
                                    userProfile.admin = snapshot.data().admin;
                                    userProfile.photoUrl = snapshot.data().photoUrl;
                                    userProfile.customProvider = true;
                                    if (snapshot.data().dob) {
                                        userProfile.dob = snapshot.data().dob;
                                    }
                                    if (remember) {
                                        if (localStorage.getItem("loginDetails")) {
                                            localStorage.removeItem("loginDetails");
                                        }
                                        let encryptedDetails = aes.encrypt(JSON.stringify({ email: email, password: password }), "LoopusWearTech").toString()
                                        localStorage.setItem("loginDetails", encryptedDetails)
                                    } else {
                                        localStorage.removeItem("loginDetails")
                                    }
                                    dispatch(loginSuccess(userProfile));
                                    window.location.reload();
                                } else {
                                    let err = {
                                        code: "insufficient-information",
                                        message: "Information about the user is not available in the Database"
                                    }
                                    dispatch(loginError(err));
                                    window.location.reload();
                                }
                            })
                            .catch(error => {
                                dispatch(loginError(error));
                                window.location.reload();
                            });
                    } else {
                        let err = {
                            code: "email-verification",
                            message: "Please verify your Email ID and try again. If you have already verified, refresh the page and try again."
                        }
                        dispatch(loginError(err));
                        window.location.reload();
                    }
                }
            })
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(loginError(err));
        window.location.reload();
    }

    return;
};

export const googleSignIn = () => (dispatch) => {
    dispatch(requestLogin());

    if (navigator.onLine) {
        fbase
            .auth()
            .signInWithPopup(googleAuth)
            .then((result) => {
                let userProfile = {
                    username: result.user.displayName,
                    photoUrl: result.user.photoURL,
                    email: result.user.email,
                    uid: result.user.uid
                }
                db.collection('userProfile').doc(result.user.uid)
                    .get()
                    .then((snapshot) => {
                        if (snapshot.data()) {
                            db.collection("userProfile").doc(result.user.uid).update({
                                username: result.user.displayName,
                                photoUrl: result.user.photoURL
                            })
                            userProfile.dob = snapshot.data().dob;
                            userProfile.admin = snapshot.data().admin;
                            userProfile.customProvider = false;
                            dispatch(loginSuccess(userProfile));
                        } else {
                            db.collection("userProfile").doc(result.user.uid).set({
                                username: result.user.displayName,
                                email: result.user.email,
                                photoUrl: result.user.photoURL,
                                dob: '',
                                admin: false,
                                uid: result.user.uid
                            })
                            userProfile.dob = '';
                            userProfile.admin = false;
                            userProfile.customProvider = false;
                            dispatch(loginSuccess(userProfile));
                        }
                        window.location.reload();
                    })
                    .catch(error => {
                        dispatch(loginError(error));
                        window.location.reload();
                    });
            }).catch(error => {
                dispatch(loginError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(loginError(err));
        window.location.reload();
    }

    return;
}

export const facebookSignIn = () => (dispatch) => {
    dispatch(requestLogin());

    if (navigator.onLine) {
        fbase
            .auth()
            .signInWithPopup(facebookAuth)
            .then((result) => {
                let userProfile = {
                    username: result.user.displayName,
                    photoUrl: result.user.photoURL,
                    email: result.user.email,
                    uid: result.user.uid
                }
                db.collection('userProfile').doc(result.user.uid)
                    .get()
                    .then((snapshot) => {
                        if (snapshot.data()) {
                            db.collection("userProfile").doc(result.user.uid).update({
                                username: result.user.displayName,
                                photoUrl: result.user.photoURL
                            })
                            userProfile.dob = snapshot.data().dob;
                            userProfile.admin = snapshot.data().admin;
                            userProfile.customProvider = false;
                            dispatch(loginSuccess(userProfile));
                        } else {
                            db.collection("userProfile").doc(result.user.uid).set({
                                username: result.user.displayName,
                                email: result.user.email,
                                photoUrl: result.user.photoURL,
                                dob: '',
                                admin: false,
                                uid: result.user.uid
                            })
                            userProfile.dob = '';
                            userProfile.admin = false;
                            userProfile.customProvider = false;
                            dispatch(loginSuccess(userProfile));
                        }
                        window.location.reload();
                    }).catch(error => {
                        dispatch(loginError(error));
                        window.location.reload();
                    });
            }).catch(error => {
                dispatch(loginError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(loginError(err));
        window.location.reload();
    }
    return;
}

export const requestLogout = () => {
    return {
        type: ActionTypes.LOGOUT_REQUEST
    }
}

export const logoutSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS
    }
}

export const logoutError = (err) => {
    return {
        type: ActionTypes.LOGOUT_FAIL,
        payload: err
    }
}

export const logoutUser = () => (dispatch) => {
    dispatch(requestLogout());

    if (navigator.onLine) {
        fbase
            .auth()
            .signOut()
            .catch(error => {
                dispatch(logoutError(error));
                window.location.reload();
            });
        dispatch(logoutSuccess());
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(logoutError(err));
        window.location.reload();
    }
    return;
}

export const resetPassword = (email) => (dispatch) => {
    dispatch(setRequest());

    if (navigator.onLine) {
        fbase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                dispatch(endRequest());
                dispatch(setInfo('An email has been sent to your email Address. Follow the instructions to reset your Password.'));
                window.location.reload();
            })
            .catch(error => {
                dispatch(setError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }
    return;
}

export const uploadFeedback = (fullname, telnum, email, agree, contactType, message) => (dispatch) => {
    dispatch(setRequest());

    if (navigator.onLine) {
        db.collection("userFeedback").doc(email).get()
            .then(snapshot => {
                if (snapshot.data()) {
                    if (agree) {
                        db.collection("userFeedback").doc(email).update({
                            telnum: telnum,
                            contactType: contactType,
                            message: message
                        }).then(() => {
                            dispatch(setInfo('Your feedback has been updated.'));
                            window.location.reload();
                        }).catch(error => {
                            dispatch(setError(error));
                            window.location.reload();
                        });
                    } else {
                        db.collection("userFeedback").doc(email).update({
                            telnum: telnum,
                            contactType: '',
                            message: message
                        }).then(() => {
                            dispatch(setInfo('Your feedback has been updated.'));
                            window.location.reload();
                        }).catch(error => {
                            dispatch(setError(error));
                            window.location.reload();
                        });
                    }
                } else {
                    if (agree) {
                        db.collection("userFeedback").doc(email).set({
                            fullname: fullname,
                            telnum: telnum,
                            email: email,
                            contactType: contactType,
                            message: message
                        }).then(() => {
                            dispatch(setInfo('Your feedback has been noted.'));
                            window.location.reload();
                        }).catch(error => {
                            dispatch(setError(error));
                            window.location.reload();
                        });
                    } else {
                        db.collection("userFeedback").doc(email).set({
                            fullname: fullname,
                            telnum: telnum,
                            email: email,
                            contactType: '',
                            message: message
                        }).then(() => {
                            dispatch(setInfo('Your feedback has been noted.'));
                            window.location.reload();
                        }).catch(error => {
                            dispatch(setError(error));
                            window.location.reload();
                        });
                    }
                }
            })
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }

    dispatch(endRequest());
}

export const emailVerification = (apiKey, oobCode) => (dispatch) => {

    dispatch(setRequest());

    if (navigator.onLine) {
        axios.post(fbBaseUrl + `accounts:update?key=${apiKey}`, { oobCode: oobCode })
            .then(res => {
                dispatch(setInfo(`Your Email ID ( ${res.data.email} ) has been successfully Verified. Now, you can login to your account.`));
                window.location.reload();
            })
            .catch(error => {
                let err = {
                    code: "email-verification",
                    message: "Either the link got expired or you have already verified your Email."
                }
                dispatch(setError(err));
                window.location.reload();
            });

    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }

    dispatch(endRequest());

}

export const changePassword = (apiKey, oobCode, newPassword) => (dispatch) => {

    dispatch(setRequest());

    if (navigator.onLine) {
        axios.post(
            fbBaseUrl + `accounts:resetPassword?key=${apiKey}`,
            {
                oobCode: oobCode,
                newPassword: newPassword
            }
        )
            .then(res => {
                dispatch(setInfo('Your password has been successfully reset. Now, you can login to your account with the new Password.'));
                window.location.reload();
            })
            .catch(error => {
                let err = {
                    code: "reset-password",
                    message: "Either the link got expired or you have already used the link."
                }
                dispatch(setError(err));
                window.location.reload();
            });

    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }

    dispatch(endRequest());

}

export const setUserName = (name) => {
    return {
        type: ActionTypes.CHANGE_USERNAME,
        payload: name
    }
}

export const updateName = (name) => (dispatch) => {

    dispatch(setRequest());

    if (navigator.onLine) {
        var user = fbase.auth().currentUser;

        user.updateProfile({
            displayName: name
        }).then(() => {
            db.collection("userProfile").doc(user.uid).update({
                username: name
            }).then(() => {
                dispatch(setUserName(name));
                dispatch(endRequest());
                dispatch(setInfo("Your name has been successfully updated."));
                window.location.reload();
            })
        }).catch((error) => {
            dispatch(setError(error));
            window.location.reload();
        });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }
}

export const deleteAccount = () => (dispatch) => {

    dispatch(setRequest());

    if (navigator.onLine) {
        var user = fbase.auth().currentUser;

        db.collection("userProfile").doc(user.uid).delete()
            .then(() => {
                storage.ref(`userPhotos/${user.uid}`).getDownloadURL()
                    .then(url => {
                        if (url)
                            storage.ref(`userPhotos/${user.uid}`).delete()
                    }).catch(error => console.log(error));
                user.delete().then(() => {
                    dispatch(logoutSuccess());
                    dispatch(setInfo("Your account has been successfully deleted."));
                    dispatch(endRequest());
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch(setError(error));
                window.location.reload();
            });
    } else {
        let err = {
            code: "network-issue",
            message: "Check your Network Connectivity. You are offline."
        }
        dispatch(setError(err));
        window.location.reload();
    }
}