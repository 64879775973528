import React from 'react';
import { Button, Col, Label, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Loading from './LoadingComponent';
import { Control, Form, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser } from '../redux/ActionCreators';
import { actions } from 'react-redux-form';

var password = "";
var dateObj = new Date();
var maxDate = String(dateObj.getFullYear() - 13) + "-" + String(dateObj.getMonth()).padStart(2, '0') + "-" + String(dateObj.getDate()).padStart(2, '0');

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
const setPassword = (val) => password = val;
const passwordMatch = (val) => val === password;

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => ({
    registerUser: (displayName, email, password, dob) => dispatch(registerUser(displayName, email, password, dob)),
    resetRegisterForm: () => { dispatch(actions.reset('register')) }
});

function Register(props) {

    const handleRegister = (values) => {
        props.registerUser(
            values.firstname + " " + values.lastname,
            values.email,
            values.password,
            values.dob
        );

        props.resetRegisterForm();
    }

    return (
        <div className="container" >
            <Breadcrumb>
                <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                <BreadcrumbItem active>Register</BreadcrumbItem>
            </Breadcrumb>
            <div className="row">
                <div className="col-11 offset-xs-1 mt-4">
                    <h3>Register</h3>
                </div>
            </div>
            <div style={{ margin: "20% 10% 30% 10%" }}>
                <Form model='register' onSubmit={(values) => handleRegister(values)}>
                    <Row className="form-group">
                        <Label htmlFor="firstname" md={3}>First Name</Label>
                        <Col md={9}>
                            <Control.text model=".firstname" id="firstname" name="firstname"
                                placeholder="Name you got from your Family"
                                className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(15)
                                }}
                            />
                            <Errors
                                className="text-danger"
                                model=".firstname"
                                show="touched"
                                messages={{
                                    minLength: 'Must be greater than 2 characters',
                                    maxLength: 'Cannot exceed 15 characters'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="lastname" md={3}>Last Name</Label>
                        <Col md={9}>
                            <Control.text model=".lastname" id="lastname" name="lastname"
                                placeholder="What your friends call you?"
                                className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(15)
                                }}
                            />
                            <Errors
                                className="text-danger"
                                model=".lastname"
                                show="touched"
                                messages={{
                                    minLength: 'Must be greater than 2 characters.',
                                    maxLength: 'Cannot exceed 15 characters.'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="email" md={3}>Email</Label>
                        <Col md={9}>
                            <Control.text model=".email" id="email" name="email"
                                placeholder="Mailing Address"
                                className="form-control"
                                validators={{
                                    required, validEmail
                                }}
                            />
                            <Errors
                                className="text-danger"
                                model=".email"
                                show="touched"
                                messages={{
                                    validEmail: 'Invalid Email Address.'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="password" md={3}>Password</Label>
                        <Col md={9}>
                            <Control.text model=".password" id="password" name="password" type="password"
                                placeholder="Secret!! Don't tell to anyone."
                                className="form-control"
                                validators={{
                                    required, minLength: minLength(7), maxLength: maxLength(15), setPassword
                                }}
                            />
                            <Errors
                                className="text-danger"
                                model=".password"
                                show="touched"
                                messages={{
                                    minLength: 'Must be greater than 6 characters.',
                                    maxLength: 'Cannot exceed 15 characters.'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="verifyPassword" md={3}>Verify Password</Label>
                        <Col md={9}>
                            <Control.text model=".verifyPassword" id="verifyPassword" name="verifyPassword" type="password"
                                placeholder="Do you remember your Secret?"
                                className="form-control"
                                validators={{
                                    required, passwordMatch
                                }}
                            />
                            <Errors
                                className="text-danger"
                                model=".verifyPassword"
                                show="touched"
                                messages={{
                                    passwordMatch: 'Passwords do not match each other.'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="dob" md={3}>Date of Birth</Label>
                        <Col>
                            <Control.text model=".dob" id="dob" name="dob" type="date"
                                className="form-control" max={maxDate}
                                validators={{ required }}
                            />
                            <Errors
                                className="text-danger"
                                model=".verifyPassword"
                                show="touched"
                                messages={{
                                    required: 'Provide your Date of Birth'
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        <Col style={{ marginTop: "4%", textAlign: "center" }}>
                            {
                                props.user.loading ?
                                    <Loading variant="grow" color="white" size="sm" /> :
                                    <Button type="submit" value="submit" color="primary" style={{ paddingLeft: "10%", paddingRight: "10%" }}> Register </Button>
                            }
                        </Col>
                    }
                </Form>
            </div>
        </div >
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);