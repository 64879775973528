import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, Col, Label, Row, Breadcrumb, BreadcrumbItem, Card, CardImg, CardText, CardBody, CardTitle, FormText } from 'reactstrap';
import Loading from './LoadingComponent';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { postComment, deleteComment, fetchComments, setError } from '../redux/ActionCreators';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const mapStateToProps = (state) => {
    return {
        comments: state.commentsReducer,
        user: state.userReducer
    }
}

const mapDispatchToProps = dispatch => ({
    postComment: (productId, rating, comment) => dispatch(postComment(productId, rating, comment)),
    fetchComments: (productId) => dispatch(fetchComments(productId)),
    deleteComment: (productId) => dispatch(deleteComment(productId)),
    setError: (err) => dispatch(setError(err))
});

const maxLength = (len) => (val) => !(val) || (val.length <= len);

function ProductDetail(props) {

    useEffect(() => {
        props.fetchComments(props.productId.toString());

        // eslint-disable-next-line
    }, []);

    const [isOpen, setisOpen] = useState(false);
    const [rating, setRating] = React.useState(4);

    const toggleModal = () => {
        setisOpen(!isOpen);
    }

    const handleSubmit = (values) => {
        console.log(values);
        toggleModal();
        if (values.comment)
            props.postComment(props.productId.toString(), rating, values.comment);
        else
            props.postComment(props.productId.toString(), rating, "");
    }

    const handleDeleteComment = () => {
        props.deleteComment(props.productId);
    }

    const Mhead = {
        backgroundColor: 'black',
        color: 'floralwhite'
    }

    const Mbody = {
        backgroundColor: '#121212',
        color: 'floralwhite'
    }

    const closeBtn = {
        backgroundColor: "black",
        color: "white",
        fontSize: "140%"
    }

    if (props.isLoading) {
        return (
            <div style={{ margin: "10% 0 10% 0%", textAlign: "center" }}>
                <Loading variant="grow" color="white" size="md" />
            </div>
        );
    }
    else if (props.product != null) {
        return (
            <div className='container'>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/products">Products</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{props.product.headName}</BreadcrumbItem>
                </Breadcrumb>

                <div className='row'>
                    <div className="col-12 mb-5 mt-5">
                        <h3>{props.product.name}</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4 mb-5">
                        <Card style={{ color: 'black' }}>
                            <CardImg variant="top" src={props.product.image} alt={props.product.name} />
                            <CardBody>
                                <CardTitle>{props.product.name}</CardTitle>
                                <CardText>
                                    {props.product.description}
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-12 offset-md-2 col-md-6 mb-5" >
                        <h4 className="mb-5">Reviews</h4>
                        <ul className="list-unstyled">
                            {props.comments.map((comment) => {
                                return (
                                    <li key={comment.uid} style={{ marginTop: "5%" }}>
                                        <p ><strong>{comment.comment}</strong></p>
                                        <Box component="fieldset" borderColor="transparent">
                                            <Rating name="read-only-rating" value={comment.rating} readOnly size="small" />
                                        </Box>
                                        <p>- {comment.username} <small>{comment.updatedAt}</small></p>

                                    </li>
                                );
                            })}
                        </ul>
                        <div style={{ marginTop: "2%" }}>
                            {
                                props.user.signedIn ?
                                    props.comments.some(cmnt => cmnt.uid === props.user.uid) ?
                                        <>
                                            <Button outline onClick={toggleModal}>
                                                <span className="fa fa-pencil fa-lg"> </span> Edit Comment
                                            </Button>
                                            <Button style={{ marginLeft: "3%", backgroundColor: "#d11a2a", borderColor: "#d11a2a" }}
                                                onClick={handleDeleteComment}>
                                                <span className="fa fa-trash fa-lg" > </span>
                                            </Button>
                                        </> :
                                        <Button outline onClick={toggleModal}>
                                            <span className="fa fa-pencil fa-lg"> </span> Submit Comment
                                        </Button>
                                    :
                                    <>
                                        <Button outline disabled>
                                            <span className="fa fa-pencil fa-lg"> </span> Submit Comment
                                        </Button>
                                        <FormText> Login to submit a comment </FormText>
                                    </>
                            }
                        </div>

                        <Modal isOpen={isOpen} toggle={toggleModal}>
                            <div className="modal-header" style={Mhead}>
                                <h4 className="modal-title">Submit Comment</h4>
                                <Button onClick={toggleModal} style={closeBtn}>
                                    &times;
                                </Button>
                            </div>
                            <ModalBody style={Mbody}>
                                <LocalForm onSubmit={(values) => handleSubmit(values)}>
                                    <Row className="form-group">
                                        <Label htmlFor="rating" md={4}>Rating</Label>
                                        <Box component="fieldset" mt={1} borderColor="transparent">
                                            <Rating
                                                name="rating"
                                                value={rating}
                                                onChange={(event, newRating) => {
                                                    setRating(newRating);
                                                }}
                                            />
                                        </Box>
                                    </Row>
                                    <Row className="form-group">
                                        <Label htmlFor="comment" md={4}>Comment</Label>
                                        <Col md={12}>
                                            <Control.textarea model=".comment" id="comment" name="comment"
                                                rows="6"
                                                className="form-control"
                                                validators={{
                                                    maxLength: maxLength(200)
                                                }}
                                            />
                                            <Errors
                                                className="text-danger"
                                                model=".comment"
                                                show="touched"
                                                messages={{
                                                    maxLength: 'Comment cannot exceed 200 characters'
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={{ size: 10, offset: 3 }}>
                                            <Button type="submit" color="primary">
                                                Post Comment
                                            </Button>
                                        </Col>
                                    </Row>
                                </LocalForm>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <></>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);