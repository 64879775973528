import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, TabContent, TabPane, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import Loading from './LoadingComponent';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';

function About(props) {

    const [isMd, setisMd] = useState(false);

    useEffect(() => {
        setisMd(window.innerWidth > 768);
        window.addEventListener("resize", setisMd(window.innerWidth > 768));

        return () => {
            window.removeEventListener("resize", setisMd(window.innerWidth > 768));
        }
    }, []);

    const imgStyle = {
        display: 'flex',
        justifyContent: 'center'
    }

    const styleRow = {
        margin: "15% 0 0 0",
        padding: "0 0 15% 0",
        borderBottom: "1px solid floralwhite"
    }

    const styleTab = {
        border: '1px solid floralwhite',
        padding: '10px'
    }

    const styleTabs = {
        margin: "15% 3% 0 3%",
        padding: "0 0 15% 0",
        borderBottom: "1px solid floralwhite",
        color: "floralwhite"
    }

    const StyleTabImgs = {
        height: "100%",
        width: "100%",
        objectFit: "contain"
    }
    const carouselImgClass = isMd ? 'd-block w-75' : 'd-block w-100';
    const [activeTab, setActiveTab] = useState('0');

    const changeTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        centerPadding: "1%",
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true
    };

    return (
        <div className="container" >
            <Breadcrumb>
                <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                <BreadcrumbItem active>About Us</BreadcrumbItem>
            </Breadcrumb>
            <div className="row">
                <div className="col-11 offset-xs-1 mt-4">
                    <h3>About Us</h3>
                </div>
            </div>
            {
                props.carousel.isLoading ?
                    <div className="row justify-content-center" style={styleRow}>
                        <Loading variant="grow" color="white" size="md" />
                    </div> :
                    <div className="row" style={styleRow}>
                        <div className="col-12">
                            <Slider {...settings}>
                                {
                                    props.carousel.carousel.map((car) => {
                                        return (
                                            <div key={car.id} className="container-carousel">
                                                <div style={imgStyle}>
                                                    <img
                                                        className={carouselImgClass}
                                                        src={car.image}
                                                        alt="First slide"
                                                    />
                                                </div>
                                                <div className="bottom-center">
                                                    <Badge color="danger" style={isMd ? { padding: "1%" } : { padding: "4%", fontSize: "60%" }}>
                                                        {car.heading}
                                                    </Badge>
                                                    <div style={isMd ? { fontSize: "65%", backgroundColor: "white", color: "#121212", marginTop: "2%", fontWeight: "bold", borderRadius: "3px" } :
                                                        { fontSize: "0" }}>
                                                        {car.description}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
            }

            {
                /*
                <Fade bottom cascade>
                    <div className="row align-items-center" style={styleRow}>
                        <div className="col-12 col-sm-8 offset-xs-1">
                            <h3>Our History</h3>
                            <br />
                            <p> We always wanted to make the living of others better using science, technology & innovation. During our early days of 2nd year B.Tech we have heard a major problem in elderly from our Professor and started working on it.</p>
                            <p> After 2 months of initial research and working on the project, we have come up with an low-cost initial prototype. We wanted to increase the reach of the innovation by releasing it as a product under a company. So, we have started pitching to many Seed Funding Incubators and finally a Pharmaceutical University have given us the grant for establishing & further development of company.</p>
                            <p> Our company has won several competitions and was even awarded the Best Innovative startup at the 2nd Connovocation of DPSRU. Under our company, we have developed two prototypes which are ready for alpha testing.</p>
                        </div>

                        <div className=" d-none d-sm-block col-sm-4">
                            <img src="https://firebasestorage.googleapis.com/v0/b/loopus-weartech.appspot.com/o/images%2Fhistory.jpg?alt=media&token=e9fbc79c-f82c-49f2-8e23-7e7370f3d301"
                                alt="Gold Medal - Loopus WearTech" className="img-thumbnail" />
                        </div>
                    </div>
                </Fade>
                */
            }

            <Fade bottom cascade>
                <div className="row" style={styleTabs}>
                    <div className="col-12" style={{ marginBottom: '4%' }}>
                        <h3>Corporate Leadership</h3>
                    </div>
                    {
                        props.leaders.isLoading ?
                            <div style={{ margin: "30% 0 0 40%" }}>
                                <Loading variant="grow" color="white" size="md" />
                            </div> :
                            <div className="col-12">
                                <Nav tabs>
                                    {
                                        props.leaders.leaders.map((ldr) => {
                                            return (
                                                <NavItem key={ldr.id}>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === ldr.id })}
                                                        onClick={() => { changeTab(ldr.id); }}
                                                    >
                                                        <p style={activeTab === ldr.id ? { display: "inline" } : { display: "inline", color: "white" }}>
                                                            {
                                                                isMd ? ldr.name : ldr.abbr
                                                            }
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        })
                                    }
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    {
                                        props.leaders.leaders.map((ldr) => {
                                            return (
                                                <TabPane tabId={ldr.id} key={ldr.id}>
                                                    <div className="row" style={styleTab}>
                                                        <div className="d-block col-sm-12 col-md-7">
                                                            <h3>{ldr.name}<small> {ldr.designation} <a href={ldr.linkedin} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></small></h3>
                                                            <p>
                                                                {ldr.description}
                                                            </p>
                                                        </div>
                                                        <div className="col-12 offset-md-1 col-md-3">
                                                            <img src={ldr.image} alt={ldr.name} style={StyleTabImgs} className="img-fluid rounded" />
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            );
                                        })
                                    }
                                </TabContent>
                            </div>
                    }
                </div>
            </Fade>
        </div>
    );
}

export default About;