import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    const footerStyle = {
        backgroundColor: "black",
        color: "floralwhite"
    }

    /* The remianing styles for footer, <a> tag in footer, address are in App.css file */

    return (
        <>
            <hr />
            <div className="footer" style={footerStyle}>
                <br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-4 offset-1 col-sm-2">
                            <h5>Links</h5>
                            <ul className="list-unstyled">
                                <li><Link to='/home'>Home</Link></li>
                                <li><Link to='/aboutus'>About Us</Link></li>
                                <li><Link to='/products'>Products</Link></li>
                                <li><Link to='/contactus'>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-7 col-sm-4">
                            <h5>Our Address</h5>
                            <address>
                                LOOPUS WEARTECH PRIVATE LIMITED<br />
                                D No. 20-3/1-76, Flat No.5, 3rd Floor,<br />
                                Sai Sidda Apartments, Palagani Prabhakar,<br />
                                AYODHYANAGAR, VIJAYAWADA, Krishna District,<br />
                                Andhra Pradesh, India - 520003.<br />
                                <i className="fa fa-phone"></i> : +91 8919029787<br />
                                <i className="fa fa-phone"></i> : +91 9440153230<br />
                                <i className="fa fa-envelope"></i> : <a
                                    href="mailto:loopusweartech@gmail.com">loopusweartech@gmail.com</a>
                            </address>
                        </div>
                        <div className="col-12 col-sm-5 footlink">
                            <a href="http://www.linkedin.com/company/loopus-weartech"><i className="fa fa-linkedin"></i></a> |
                            <a href="mailto:loopusweartech@gmail.com"><i className="fa fa-envelope"></i></a>
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <p>© Copyright 2020 Loopus WearTech Pvt. Ltd.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;