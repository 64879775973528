import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarToggler, Nav, NavItem, Button, Jumbotron,
    Modal, ModalBody, Col, Label, Row, Alert
} from 'reactstrap';
import Loading from './LoadingComponent';
import { Control, LocalForm, Form, Errors } from 'react-redux-form';
import { NavLink } from 'react-router-dom';
import { baseUrl } from '../shared/baseUrl';
import { connect } from 'react-redux';
import { loginUser, logoutUser, resetPassword, googleSignIn, facebookSignIn, resetInfo, resetError } from '../redux/ActionCreators';
import { actions } from 'react-redux-form';

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => ({
    loginUser: (email, password, remember) => dispatch(loginUser(email, password, remember)),
    googleSignIn: () => dispatch(googleSignIn()),
    facebookSignIn: () => dispatch(facebookSignIn()),
    logoutUser: () => dispatch(logoutUser()),
    forgotPassword: (email) => dispatch(resetPassword(email)),
    resetLoginForm: () => { dispatch(actions.reset('login')) },
    resetInfo: () => { dispatch(resetInfo()) },
    resetError: () => { dispatch(resetError()) }
});

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            isModalOpen: false,
            isForgotModalOpen: false,
            showInfo: true,
            showAlert: true
        };
    }

    toggleNav = () => {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    toggleForgotModal = () => {
        this.setState({
            isForgotModalOpen: !this.state.isForgotModalOpen,
            isModalOpen: false
        });
    }

    handleAlert = () => {
        this.setState({
            showAlert: false
        })
        this.props.resetError();
    }

    handleInfo = () => {
        this.setState({
            showInfo: false
        })
        this.props.resetInfo();
    }

    handleForgotPassword = (values) => {
        this.props.forgotPassword(values.email);
        this.props.resetForgotForm();
        this.toggleForgotModal();
    }

    handleLogout = () => {
        this.props.logoutUser();
    }

    handleLogin = (values) => {
        this.props.loginUser(values.email, values.password, values.remember);
        this.toggleModal();
        this.props.resetLoginForm();
    }

    handleGoogleSignIn = () => {
        this.props.googleSignIn();
        this.toggleModal();
        this.props.resetLoginForm();
    }

    handleFacebookSignIn = () => {
        this.props.facebookSignIn();
        this.toggleModal();
        this.props.resetLoginForm();
    }

    render() {
        const navbar = {
            backgroundColor: "black",
            color: "floralwhite"
        }

        const LoginStyle = {
            margin: "2% 0 2% 0"
        }

        const jumboStyle = {
            backgroundColor: "floralwhite",
            color: "black",
            padding: "10% 0 10% 0",
            textAlign: "center",
            marginBottom: "0"
        }

        const Mhead = {
            backgroundColor: 'black',
            color: 'floralwhite'
        }

        const Mbody = {
            backgroundColor: '#121212',
            color: 'floralwhite'
        }

        const closeBtn = {
            backgroundColor: "black",
            color: "white",
            fontSize: "140%"
        }
        return (
            <>
                <Navbar style={navbar} dark expand="md">
                    <div className="container">
                        <NavLink className="nav-link mr-auto" to="/home">
                            <img src={baseUrl + "images/logo_white.png"} height="40" width="140" alt="logo white" />
                        </NavLink>
                        <NavbarToggler onClick={this.toggleNav} />
                        <Collapse navbar isOpen={this.state.isNavOpen} style={{ margin: "3% 0 3% 3%" }}>
                            <Nav className="mr-auto" navbar>
                                <Nav navbar>
                                    <NavItem>
                                        <NavLink className="nav-link" to="/home">
                                            <span className="fa fa-home fa-lg"></span> Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link" to="/aboutus">
                                            <span className="fa fa-info fa-lg"></span> About Us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link" to="/products">
                                            <span className="fa fa-list fa-lg"></span> Products
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link" to="/contactus">
                                            <span className="fa fa-address-card fa-lg"></span> Contact
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Nav>
                            {
                                this.props.user.loading ?
                                    <Loading size="xs" color="white" variant="grow" /> :
                                    this.props.user.signedIn ?
                                        <Nav className="ml-auto justify-content-center" navbar>
                                            <NavLink to="/settings" className="mt-2 mt-md-0 mb-2 mb-md-0">
                                                {this.props.user.photoUrl && <img style={{ borderRadius: "50%" }} src={this.props.user.photoUrl} height="45" width="45" alt="Profile" />}
                                                <div className="navbar-text ml-2 mr-3"> {this.props.user.username} </div>
                                            </NavLink>
                                            <NavItem style={LoginStyle}>
                                                <NavLink to="/home">
                                                    <Button outline onClick={this.handleLogout}>
                                                        <span className="fa fa-sign-out fa-lg"> </span> Logout
                                                    </Button>
                                                </NavLink>
                                            </NavItem>
                                        </Nav> :
                                        <Nav className="ml-auto" navbar>
                                            <NavItem style={LoginStyle} className="mt-2">
                                                <Button outline onClick={this.toggleModal}>
                                                    <span className="fa fa-sign-in fa-lg"> </span> Login
                                                </Button>
                                            </NavItem>
                                        </Nav>
                            }
                        </Collapse>
                    </div>
                </Navbar>
                <Jumbotron style={jumboStyle}>
                    <div className="container">
                        <div className="row row-header">
                            <div className="col-12 col-sm-6">
                                <h1>Loopus WearTech</h1>
                                <p>An Innovation driven startup incubated at DPSRU Incubation and Innovation Foundation.</p>
                            </div>
                            <div className="col-12 offset-sm-1 col-sm-4 align-self-center">
                                <img src={baseUrl + "images/logo_black.png"} className="img-fluid" height="50" width="300"
                                    alt="logo black" />
                            </div>
                        </div>
                    </div>
                </Jumbotron>
                <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                    <div className="modal-header" style={Mhead}>
                        <h4 className="modal-title">Login</h4>
                        <Button onClick={this.toggleModal} style={closeBtn}>
                            &times;
                        </Button>
                    </div>
                    <ModalBody style={Mbody}>
                        <Form model='login' onSubmit={(values) => this.handleLogin(values)}>
                            <Row className="form-group">
                                <Label htmlFor="email" md={3}>Email</Label>
                                <Col md={9}>
                                    <Control.text model=".email" id="email" name="email"
                                        type="text"
                                        placeholder="Email Address"
                                        className="form-control"
                                        validators={{
                                            required, validEmail
                                        }}
                                    />
                                    <Errors
                                        className="text-danger"
                                        model=".email"
                                        show="touched"
                                        messages={{
                                            validEmail: 'Invalid Email Address.'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="password" md={3}>Password</Label>
                                <Col md={9}>
                                    <Control.text model=".password" id="password" name="password"
                                        type="password"
                                        placeholder="Password"
                                        className="form-control"
                                        validators={{
                                            required, minLength: minLength(7), maxLength: maxLength(15)
                                        }}
                                    />
                                    <Errors
                                        className="text-danger"
                                        model=".password"
                                        show="touched"
                                        messages={{
                                            minLength: 'Must be greater than 6 characters.',
                                            maxLength: 'Cannot exceed 15 characters.'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col xs={{ offset: 7 }} md={{ offset: 8 }}>
                                    <div className="form-check">
                                        <Label check>
                                            <Control.checkbox model=".remember" name="remember"
                                                className="form-check-input"
                                            /> {' '}
                                            <strong>Remember me</strong>
                                        </Label>
                                    </div>
                                </Col>
                            </Row>
                            <Col style={{ marginTop: "4%", textAlign: "center" }}>
                                <Button
                                    type="submit"
                                    value="submitLogin"
                                    color="primary"
                                    style={{ paddingLeft: "10%", paddingRight: "10%" }}
                                >Login</Button>
                            </Col>
                            {
                                window.innerWidth > 768 ?
                                    <Col style={{ textAlign: "center", marginTop: "10%" }}>
                                        <Button onClick={this.handleGoogleSignIn} style={{ backgroundColor: "#DB4437", marginRight: "5%", borderColor: "#DB4437" }}>
                                            <span className="fa fa-google fa-lg mr-1"> </span> Sign In with Google
                                        </Button>
                                        <Button onClick={this.handleFacebookSignIn} style={{ backgroundColor: "#4267B2", borderColor: "#4267B2" }}>
                                            <span className="fa fa-facebook fa-lg mr-1"> </span> Sign In with Facebook
                                        </Button>
                                    </Col> :
                                    <Col style={{ textAlign: "center", marginTop: "10%" }}>
                                        <Button onClick={this.handleGoogleSignIn} style={{ backgroundColor: "#DB4437", marginRight: "15%", borderColor: "#DB4437" }}>
                                            <span className="fa fa-google fa-lg mr-1"> </span> Google
                                        </Button>
                                        <Button onClick={this.handleFacebookSignIn} style={{ backgroundColor: "#4267B2", borderColor: "#4267B2" }}>
                                            <span className="fa fa-facebook fa-lg mr-1"> </span> Facebook
                                        </Button>
                                    </Col>
                            }
                            <Col style={{ textAlign: "center", marginTop: "8%" }}>
                                <button onClick={this.toggleForgotModal} style={{ all: "unset" }} type="button">
                                    Forgot Password?
                                </button>
                            </Col>
                            <Col style={{ textAlign: "center", marginTop: "4%" }}>
                                <NavLink className="nav-link" to="/register" style={{ color: "white", fontWeight: "bold" }} onClick={this.toggleModal}>
                                    Not a User? Register
                                </NavLink>
                            </Col>
                        </Form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.isForgotModalOpen} toggle={this.toggleForgotModal}>
                    <div className="modal-header" style={Mhead}>
                        <h4 className="modal-title">Forgot Password</h4>
                        <Button onClick={this.toggleForgotModal} style={closeBtn}>
                            &times;
                        </Button>
                    </div>
                    <ModalBody style={Mbody}>
                        <LocalForm onSubmit={this.handleForgotPassword}>
                            <Row className="form-group">
                                <Label htmlFor="forgotEmail" md={3}>Email</Label>
                                <Col md={9}>
                                    <Control.text model=".email" id="forgotEmail" name="forgotEmail"
                                        type="text"
                                        placeholder="Email Address"
                                        className="form-control"
                                        validators={{
                                            required, validEmail
                                        }}
                                    />
                                    <Errors
                                        className="text-danger"
                                        model=".email"
                                        show="touched"
                                        messages={{
                                            validEmail: 'Invalid Email Address.'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Col style={{ marginTop: "4%", textAlign: "center" }}>
                                <Button
                                    type="submit"
                                    value="submitForgot"
                                    color="primary"
                                    style={{ paddingLeft: "10%", paddingRight: "10%" }}
                                >Submit</Button>
                            </Col>
                        </LocalForm>
                    </ModalBody>
                </Modal>
                {
                    this.props.user.info ?
                        <div className="container mt-3">
                            <Alert isOpen={this.state.showInfo} toggle={this.handleInfo} color="success">
                                <h6> {this.props.user.info} </h6>
                            </Alert>
                        </div>
                        :
                        this.props.user.err ?
                            <div className="container mt-3">
                                <Alert isOpen={this.state.showAlert} toggle={this.handleAlert} color="danger">
                                    <h4> Error: {this.props.user.err.code}</h4>
                                    {this.props.user.err.message}
                                </Alert>
                            </div>
                            : <div></div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);