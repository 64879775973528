import React from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Label, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Control, Form, Errors } from 'react-redux-form';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { uploadFeedback } from '../redux/ActionCreators';
import { actions } from 'react-redux-form';

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => ({
    uploadFeedback: (fullname, telnum, email, agree, contactType, message) => { dispatch(uploadFeedback(fullname, telnum, email, agree, contactType, message)) },
    resetFeedbackForm: () => { dispatch(actions.reset('feedback')) }
});

function Contact(props) {

    const handleSubmit = (values) => {
        if (props.user.signedIn) {
            props.uploadFeedback(props.user.username, values.telnum, props.user.email, values.agree, values.contactType, values.message);
        } else {
            props.uploadFeedback(values.fullname, values.telnum, values.email, values.agree, values.contactType, values.message);
        }
        props.resetFeedbackForm();
    }

    const styleRow = {
        margin: "20% 0 0 0",
        padding: "0 0 25% 0",
        borderBottom: "1px solid floralwhite"
    }

    return (
        <div className="container" >
            <Breadcrumb>
                <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                <BreadcrumbItem active>Contact Us</BreadcrumbItem>
            </Breadcrumb>

            <div className="row">
                <div className="col-11 offset-xs-1 mt-4">
                    <h3>Contact Us</h3>
                </div>
            </div>

            <Fade bottom cascade>
                <div className="row" style={styleRow}>
                    <div className="col-12">
                        <h3>Location Information</h3>
                    </div>
                    <div className="col-12 col-sm-4 offset-sm-1">
                        <br />
                        <h5 className="mb-3">Our Address</h5>
                        <address>
                            LOOPUS WEARTECH PRIVATE LIMITED<br />
                            D No. 20-3/1-76, Flat No.5, 3rd Floor,<br />
                            Sai Sidda Apartments, Palagani Prabhakar,<br />
                            AYODHYANAGAR, VIJAYAWADA, Krishna District,<br />
                            Andhra Pradesh, India - 520003.<br />
                            <i className="fa fa-phone"></i> : +91 8919029787<br />
                            <i className="fa fa-phone"></i> : +91 9440153230<br />
                            <i className="fa fa-envelope"></i> : <a
                                href="mailto:loopusweartech@gmail.com">loopusweartech@gmail.com</a>
                        </address>
                    </div>
                    <div className="col-12 col-sm-6 offset-sm-1">
                        <br />
                        <h5 className="mb-3">Map of our Location</h5>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d565.828269688999!2d80.63360433464366!3d16.52989234339713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35e558b4db337f%3A0xa37132717b9cb047!2sPalagani%20Prabhakar%20St%2C%20Ramalingeswarapet%2C%20Ayodhya%20Nagar%2C%20Vijayawada%2C%20Andhra%20Pradesh%20520003!5e0!3m2!1sen!2sin!4v1593003758599!5m2!1sen!2sin"
                            width="300" height="225" frameBorder="0" allowFullScreen="" aria-hidden="false" title="Map of the Location"
                            tabIndex="0">
                        </iframe>
                    </div>
                    <div className="col-12 col-sm-11 offset-sm-1">
                        <br />
                        <div className="btn-group" role="group">
                            <a href="tel:+918919029787" className="btn btn-primary" role="button"><i className="fa fa-phone"></i> Call</a>
                            <a href="mailto:loopusweartech@gmail.com" className="btn btn-success" role="button">
                                <i className="fa fa-envelope-o"></i> Email
                            </a>
                        </div>
                    </div>
                </div>
            </Fade>

            <div className="row" style={styleRow}>
                <div className="col-12 mb-4">
                    <h3>Send us your Feedback</h3>
                </div>
                <div className="col-12 col-md-9">
                    <Form model='feedback' onSubmit={(values) => handleSubmit(values)}>
                        <Row className="form-group">
                            <Label htmlFor="fullname" md={2}>Full Name</Label>
                            {
                                props.user.signedIn ?
                                    <Col md={10}>
                                        <Control.text model=".fullname" id="fullname" name="fullname"
                                            placeholder="Complete Name"
                                            className="form-control"
                                            value={props.user.username}
                                            disabled
                                        />
                                    </Col>
                                    :
                                    <Col md={10}>
                                        <Control.text model=".fullname" id="fullname" name="fullname"
                                            placeholder="Complete Name"
                                            className="form-control"
                                            validators={{
                                                required, minLength: minLength(3), maxLength: maxLength(20)
                                            }}
                                        />
                                        <Errors
                                            className="text-danger"
                                            model=".fullname"
                                            show="touched"
                                            messages={{
                                                minLength: 'Must be greater than 2 characters',
                                                maxLength: 'Must be 20 characters or less'
                                            }}
                                        />
                                    </Col>
                            }
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="telnum" md={2}>Contact Tel.</Label>
                            <Col md={10}>
                                <Control.text model=".telnum" id="telnum" name="telnum"
                                    placeholder="Tel. Number"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15), isNumber
                                    }}
                                />
                                <Errors
                                    className="text-danger"
                                    model=".telnum"
                                    show="touched"
                                    messages={{
                                        minLength: '\nMust be greater than 2 numbers',
                                        maxLength: '\nMust be 15 numbers or less',
                                        isNumber: '\nMust be a number'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="emailContact" md={2}>Email</Label>
                            {
                                props.user.signedIn ?
                                    <Col md={10}>
                                        <Control.text model=".email" id="email" name="email"
                                            placeholder="Email Address"
                                            className="form-control"
                                            value={props.user.email}
                                            disabled
                                        />
                                    </Col>
                                    :
                                    <Col md={10}>
                                        <Control.text model=".email" id="emailContact" name="emailContact"
                                            placeholder="Email"
                                            className="form-control"
                                            validators={{
                                                required, validEmail
                                            }}
                                        />
                                        <Errors
                                            className="text-danger"
                                            model=".email"
                                            show="touched"
                                            messages={{
                                                required: 'Required and ',
                                                validEmail: 'Invalid Email Address'
                                            }}
                                        />
                                    </Col>
                            }
                        </Row>
                        <Row className="form-group">
                            <Col md={{ size: 6, offset: 2 }} className="align-self-center mb-3 mt-3">
                                <div className="form-check">
                                    <Label check>
                                        <Control.checkbox model=".agree" name="agree"
                                            className="form-check-input"
                                        /> {' '}
                                        <strong>May we contact you?</strong>
                                    </Label>
                                </div>
                            </Col>
                            <Col md={{ size: 3, offset: 1 }}>
                                <Control.select model=".contactType" name="contactType"
                                    className="form-control">
                                    <option>Tel.</option>
                                    <option>Email</option>
                                </Control.select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="message" md={2}>Your Feedback</Label>
                            <Col md={10}>
                                <Control.textarea model=".message" id="message" name="message"
                                    rows="12"
                                    className="form-control"
                                    validators={{ required }}
                                />
                                <Errors
                                    className="text-danger"
                                    model=".message"
                                    show="touched"
                                    messages={{
                                        required: 'Feedback cannot be empty'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={{ size: 10, offset: 2 }}>
                                <Button type="submit" color="primary">
                                    Send Feedback
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div >
            </div >
        </div >
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);