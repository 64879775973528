import * as ActionTypes from './ActionTypes';

export const carouselReducer = (state = {
    isLoading: false,
    carousel: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_CAROUSEL:
            return { isLoading: false, carousel: action.payload };

        case ActionTypes.CAROUSEL_LOADING:
            return { isLoading: true, carousel: [] }

        default:
            return state;
    }
};