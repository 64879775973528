import * as ActionTypes from './ActionTypes';

export const productsReducer = (state = {
    isLoading: false,
    products: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_PRODUCTS:
            return { isLoading: false, products: action.payload };

        case ActionTypes.PRODUCTS_LOADING:
            return { isLoading: true, products: [] }

        default:
            return state;
    }
};