import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Loading from './LoadingComponent';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

function RenderProduct({ product }) {
    const styleRow = {
        margin: "20% 0 0 0",
        padding: "0 0 20% 0",
        borderBottom: "1px solid floralwhite"
    }

    const order = (id) => {
        if (id % 2 === 0) {
            return 'order-md-last';
        } else {
            return 'order-md-first';
        }
    }

    return (
        <Fade bottom cascade>
            <div className="row align-items-center" style={styleRow}>
                <div className={"col-11 offset-1 offset-md-0 col-md-3 mb-4 mb-md-0 " + order(product.id)}>
                    <h3>{product.altName}</h3>
                </div>
                <div className="col col-sm col-md">
                    <div className="media">
                        <img className={"d-flex align-self-center " + order(product.id + 1)} src={product.image}
                            alt={product.name} width={product.width} height={product.height} />
                        <div className="media-body align-self-center ml-4 mr-5">
                            <Link to={`/products/${product.id}`}>
                                <h4>{product.name}</h4>
                            </Link>
                            <p className="d-none d-sm-block mt-3">
                                {product.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        </Fade >
    );
}

function Products(props) {

    if (props.products.products != null) {
        return (
            <div className="container">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Products</BreadcrumbItem>
                </Breadcrumb>

                {
                    props.products.isLoading ?
                        <div className="row justify-content-center">
                            <Loading variant="grow" color="white" size="md" />
                        </div>
                        :
                        props.products.products ?
                            props.products.products.map((product) => {
                                return (
                                    <div key={product.id}>
                                        <RenderProduct product={product} />
                                    </div>
                                );
                            })
                            : <></>
                }

            </div >
        );
    }
}

export default Products;