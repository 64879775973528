export const ADD_COMMENTS = 'ADD_COMMENTS';
export const COMMENTS_LOADING = 'COMMENTS_LOADING';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const LEADERS_LOADING = 'LEADERS_LOADING';
export const ADD_LEADERS = 'ADD_LEADERS';
export const CAROUSEL_LOADING = 'CAROUSEL_LOADING';
export const ADD_CAROUSEL = 'ADD_CAROUSEL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const SET_PHOTOURL = "SET_PHOTOURL";
export const SET_INFO = 'SET_INFO';
export const RESET_INFO = 'RESET_INFO';
export const SET_REQUEST = 'SET_REQUEST';
export const END_REQUEST = 'END_REQUEST';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';